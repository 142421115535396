import uuid from "uuid";
import { PanelDef } from "../PanelDef";
import { ViewLineChart } from "./ViewLineChart";
import { EditLineChartMeta } from "./EditLineChartMeta";

export type Timeseries = {
  table: string;
  column: string;
  aggregator: string;
  query?: any;
  groupBys?: string[];
};

export type TimeseriesAxisMap = {
  table: string;
  column: string;
  aggregator: string;
  alternateAxis: boolean;
  query?: any;
  groupBys?: string[];
};

export class LineChartMetaData {
  type = "line_chart";
  timeseries: Timeseries[] = [];
  alternateAxisMap: TimeseriesAxisMap[] = [];
  id: string = uuid.v4();
  title: string = "";
  description: string = "";
  showAreaChart: boolean = false;
  connectNullValues: boolean = true;
  xAxisLabel: string = "";
  yAxisLabel: string = "";
  xAxisUnit: string = "";
  yAxisUnit: string = "";
  xAxisGrids: boolean = true;
  yAxisGrids: boolean = true;
  lineWidth: number = 2;
  markerRadius: number = 6;
  threshold: number | undefined = undefined; // upper threshold
  lowerThreshold: number | undefined = undefined;
  showMarkers: boolean = true;
  rangeToZero: boolean = false;
  minRange: number | undefined = undefined;
  maxRange: number | undefined = undefined;
  groupBys?: string[];
  dashboardType?: string;
  autoMarginEnable?: boolean = true;
  leftMarginYaxis?: number = 0;
}

export type LineChartData = {
  table: string;
  label: string;
  aggregator: string;
  group: { [key: string]: string };
  data: {
    timestamp: number;
    value: number | null | string;
  }[];
}[];

export const LineChartPanelDef: PanelDef<LineChartMetaData, LineChartData> = {
  ViewComponent: ViewLineChart,
  EditComponent: EditLineChartMeta,
  formatData: (data: any) => data as LineChartData,
  metaConstructor: LineChartMetaData,
  previewAspectRatio: { width: 500, height: 300 },
};

import React, { useEffect, useState } from "react";
import Plot from "react-plotly.js";
import { useUser } from "../../../../context/User.context";
import LoadingAnimation from "../../../common/Loader";
import ThemeSchema from "../../../../theme/schema";
import {
  ActionStatusType,
  fetchDeviceActionLatency,
  fetchDeviceActionLatencyStates,
} from "../../../../BytebeamClient";
import { LatencyHistogramStats } from "../../../../util";
import ActionButtonV3 from "../ActionsV3/ActionButton";
import { capitalizeFirstLetter } from "../../util";

type ActionsLatencyHistogramProps = {
  readonly height: number;
  readonly selectedPhase: string;
  readonly selectedAction: ActionStatusType;
};

export function ActionsLatencyHistogram(props: ActionsLatencyHistogramProps) {
  const { selectedAction, selectedPhase } = props;

  const { user } = useUser();
  const theme = user?.settings?.theme;

  const [y, setY] = useState<number[]>([]);
  const [xStart, setXStart] = useState<number[]>([]);
  const [xEnd, setXEnd] = useState<number[]>([]);
  const [actionStates, setActionStates] = useState<string[]>([]);
  const [actionState, setActionState] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const setHistogramData = (stats) => {
      const xStart = stats.map((stat) => stat.range_start);
      const xEnd = stats.map((stat) => stat.range_end);
      const y = stats.map((stat) => stat.count);

      setXStart(xStart);
      setXEnd(xEnd);
      setY(y);
    };

    const fetchLatencyStats = async () => {
      try {
        setLoading(true);

        let states = [...actionStates];
        states = await fetchDeviceActionLatencyStates(selectedAction.action_id);
        setActionStates(states);

        const latencyStats: LatencyHistogramStats =
          await fetchDeviceActionLatency(
            selectedAction.action_id,
            actionState === "" ? states[0] : actionState
          );

        setActionState(actionState === "" ? states[0] : actionState);

        setHistogramData(latencyStats);
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };
    fetchLatencyStats();
  }, [props.selectedAction.action_id, selectedPhase, actionState]); // eslint-disable-line react-hooks/exhaustive-deps

  if (loading) {
    return (
      <LoadingAnimation
        loaderSize="28px"
        fontSize="18px"
        loaderBorderSize="3px"
        marginTopText="6px"
        loadingText="Loading latency histogram..."
      />
    );
  } else if (y?.length === 0) {
    return <div>Latency Histogram is not available...</div>;
  } else {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          flexDirection: "column",
          height: "100%",
          width: "100%",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "flex-start",
            flexDirection: "row",
            gap: "6px",
            margin: "8px 0px",
            width: "100%",
          }}
        >
          {actionStates.map((status) => (
            <ActionButtonV3
              key={status}
              onClick={() => setActionState(status)}
              label={capitalizeFirstLetter(status)}
              selected={actionState === status}
              margin_left={"0px"}
              padding={"0rem .5rem"}
              button_height={"24px"}
            />
          ))}
        </div>
        <Plot
          data={[
            {
              name: "Latency Histogram",
              x: xStart,
              y,
              width: xEnd.map((end, i) => (end - xStart[i]) / 1000),
              type: "bar",
              orientation: "v",
              marker: {
                showscale: false,
                color: "#7C3EFF",
              },
              showlegend: false,
              hoverinfo: "y",
            },
          ]}
          useResizeHandler
          layout={{
            autosize: true,
            height: props.height,
            margin: {
              l: 42,
              r: 12,
              b: 36,
              t: 18,
            },
            xaxis: {
              color:
                ThemeSchema.data[theme ?? "dark"]?.colors["chart-text-color"],
              showline: true,
              showgrid: false,
              fixedrange: true,
              tickmode: "auto",
              title: "Time (seconds)",
              range: [0, Math.max(...xEnd) / 1000 + 5],
            },
            yaxis: {
              color:
                ThemeSchema.data[theme ?? "dark"]?.colors["chart-text-color"],
              showgrid: false,
              fixedrange: true,
              range: [0, Math.max(...y) + 5],
              title: "Devices",
            },
            paper_bgcolor: "transparent",
            plot_bgcolor: "transparent",
          }}
          config={{
            displayModeBar: false,
          }}
        />
      </div>
    );
  }
}

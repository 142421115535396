import React, { useCallback, useEffect, useRef, useState } from "react";
import { Button, Grid, Icon, Popup, Table } from "semantic-ui-react";
import { ErrorMessage } from "../../../common/ErrorMessage";
import {
  DBCOffsetResponse,
  DBCResponse,
  ParsedDBCResponse,
} from "../../../../util";
import {
  deleteDBC,
  fetchAllDBCs,
  fetchDBCFile,
  fetchParsedDBC,
  startDBCParser,
  stopDBCParser,
} from "../../../../BytebeamClient";
import {
  ButtonGroupedIcon,
  ButtonIcon,
  capitalizeFirstLetter,
} from "../../util";
import ConfirmationModal from "../../common/ConfirmationModal";
import ConfirmationModalMessage from "../../common/ConfirmationModalMessage";
import moment from "moment";
import LoadingAnimation from "../../../common/Loader";
import { beamtoast } from "../../../common/CustomToast";
import CreateOrEditDBCModal from "./CreateOrEditDBCModal";
import { useLocation } from "react-router-dom";
import ViewDBCTableModal from "./ViewDBCTableModal";
import styled from "styled-components";
import BrowserUpdatedIcon from "../../../../assets/svg/BrowserUpdatedIcon";
import TextWithToolTip from "../../DeviceManagement/Devices/TextWithToolTip";
import UpdateDBCParsingModal from "./UpdateDBCParsingModal";

const OffsetDiv = styled.div`
  white-space: nowrap;

  &:not(:last-child) {
    margin-bottom: 3px;
  }
`;

export enum DBCOperationType {
  Create = "create",
  Edit = "edit",
}

export default function DBC() {
  const location = useLocation();
  const abortControllerRef = useRef(new AbortController());
  const isMounted = useRef<boolean>(true);
  const timeoutId = useRef<NodeJS.Timeout | null>(null);

  const [DBCData, setDBCData] = useState<DBCResponse[]>([]);
  const [parsedDBCData, setParsedDBCData] = useState<ParsedDBCResponse>();
  const [selectedDBC, setSelectedDBC] = useState<DBCResponse>();
  const [loading, setLoading] = useState<boolean>(true);
  const [errorOccurred, setErrorOccurred] = useState<boolean>(false);

  const [newDBCName, setNewDBCName] = useState<string>();

  const [open, setOpen] = useState(false);
  const [operationType, setOperationType] = useState<string>(
    DBCOperationType.Create
  );

  const [viewDBCTableModal, setViewDBCTableModal] = useState<boolean>(false);
  const [isUpdateDBCParsingModalOpen, setIsUpdateDBCParsingModalOpen] =
    useState<boolean>(false);

  const handleUpdate = useCallback(async (reset: boolean) => {
    if (!isMounted.current) return;

    if (reset) {
      // Check if there's an ongoing fetch request
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }

      // Create a new AbortController for the new API request
      abortControllerRef.current = new AbortController();
    }

    try {
      const res = await fetchAllDBCs(abortControllerRef.current.signal);
      setDBCData(res.results);
    } catch (error) {
      if (error instanceof DOMException && error.name === "AbortError") {
        console.log("Fetch aborted");
      } else {
        console.error("Error occurred in apiCall:", error);
        setErrorOccurred(true);
      }
    } finally {
      setLoading(false);
      if (isMounted.current) {
        timeoutId.current = setTimeout(() => {
          handleUpdate(false);
        }, 2 * 1000);
      }
    }
  }, []);

  const handleModalClose = () => {
    setOpen(false);
    setSelectedDBC(undefined);
    setOperationType(DBCOperationType.Create);
  };

  const startDBC = async (dbc: DBCResponse) => {
    const DbcID = dbc.id;
    const DBCName = dbc.name;
    try {
      setLoading(true);
      await startDBCParser(DbcID);
      beamtoast.success(`Started DBC Parser "${DBCName}"`);
      handleUpdate(true);
      setNewDBCName("");
    } catch (e) {
      beamtoast.error(`Failed to start DBC Parser "${DBCName}"`);
      console.log(e);
    }
  };

  const stopDBC = async (dbc: DBCResponse) => {
    const DbcID = dbc.id;
    const DBCName = dbc.name;
    try {
      setLoading(true);
      await stopDBCParser(DbcID);
      beamtoast.success(`Stopped DBC Parser "${DBCName}"`);
      handleUpdate(true);
    } catch (e) {
      beamtoast.error(`Failed to stop DBC Parser "${DBCName}"`);
      console.log(e);
    }
  };

  const renderToggleButton = (dbc: DBCResponse, status: string) => {
    if (status === "stopped") {
      return (
        <Popup
          content="Click here to start DBC Parser"
          inverted
          position="top center"
          open={dbc.name === newDBCName}
          trigger={
            <ButtonIcon link name="play" onClick={() => startDBC(dbc)} />
          }
        />
      );
    } else {
      return (
        <Popup
          content="Stop DBC Parser"
          inverted
          position="top center"
          trigger={
            <ButtonIcon link name="pause" onClick={() => stopDBC(dbc)} />
          }
        />
      );
    }
  };

  const renderDeleteButton = (dbc: DBCResponse) => {
    const DbcID = dbc.id;
    const DBCName = dbc.name;
    return (
      <Popup
        content="Delete DBC Parser"
        position="top center"
        inverted
        trigger={
          <div>
            <ConfirmationModal
              trigger={
                <ButtonIcon style={{ marginTop: "5px" }} link name="trash" />
              }
              prefixContent="Delete DBC Parser"
              expectedText={DBCName}
              message={
                <ConfirmationModalMessage
                  name={DBCName}
                  type={"DBC"}
                  specialMessage=""
                />
              }
              onConfirm={async () => {
                if (dbc.status === "started")
                  beamtoast.error("Cannot delete in runnning state!");
                else {
                  setLoading(true);
                  try {
                    await deleteDBC(DbcID);
                    beamtoast.success(`Deleted DBC Parser"${DBCName}"`);
                  } catch (e) {
                    beamtoast.error(`Failed to delete DBC Parser "${DBCName}"`);
                    console.log(e);
                  } finally {
                    handleUpdate(true);
                  }
                }
              }}
            />
          </div>
        }
      />
    );
  };

  const downloadFile = (
    content: string,
    fileName: string,
    contentType: string
  ) => {
    const blob = new Blob([content], { type: `${contentType};charset=utf-8` });
    const url = URL.createObjectURL(blob);
    const dbcFile = document.createElement("a");
    dbcFile.href = url;
    dbcFile.download = `${fileName}.dbc`;
    dbcFile.click();
    URL.revokeObjectURL(url);
  };

  const downloadDBCFile = async (dbcID: string, dbcName: string) => {
    const res = await fetchDBCFile(dbcID);
    const dbcFileContent = res.results.dbc;
    const dbcFileName = dbcName;
    const contentType = "text/plain";

    downloadFile(dbcFileContent, dbcFileName, contentType);
  };

  const fetchDBCJSON = async (dbcID: string) => {
    setViewDBCTableModal(true);
    const res = await fetchParsedDBC(dbcID);
    setParsedDBCData(res);
  };

  const editDBCParser = (dbcID: string) => {
    const currentDBC = DBCData.find((dbc) => {
      return dbc.id === dbcID;
    });
    setSelectedDBC(currentDBC);
    setOperationType(DBCOperationType.Edit);
    setOpen(true);
  };

  function sortShards(shards: DBCOffsetResponse) {
    // Get the keys and sort them
    const sortedKeys = Object.keys(shards).sort((a, b) => {
      const shardNumberA = parseInt(a.split("-")[1], 10);
      const shardNumberB = parseInt(b.split("-")[1], 10);
      return shardNumberA - shardNumberB; // Sort numerically
    });

    // Rebuild the object in sorted order
    const sortedShards = {};
    sortedKeys.forEach((key) => {
      sortedShards[key] = shards[key];
    });

    return sortedShards;
  }

  useEffect(() => {
    handleUpdate(false);

    // Clean up the timeout and abort the fetch when the component unmounts
    return () => {
      isMounted.current = false;
      if (timeoutId.current) clearTimeout(timeoutId.current);

      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }
    };
  }, [handleUpdate]);

  useEffect(() => {
    document.title = "DBC Parsers | Bytebeam";

    setTimeout(() => {
      setNewDBCName("");
    }, 30000); // remove popup after a while
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  if (errorOccurred) {
    return <ErrorMessage marginTop="270px" errorMessage />;
  }

  if (loading) {
    return (
      <LoadingAnimation
        loaderContainerHeight="calc(100vh - 130px)"
        fontSize="1.5rem"
        loadingText={
          location.pathname.includes("dbc") ? "Loading DBCs" : "Loading..."
        }
      />
    );
  }

  return (
    <Grid>
      <CreateOrEditDBCModal
        open={open}
        onOpen={() => setOpen(true)}
        onClose={handleModalClose}
        selectedDBC={selectedDBC}
        DBCData={DBCData}
        operationType={operationType}
        setNewDBCName={(name) => setNewDBCName(name)}
        handleUpdate={() => handleUpdate(true)}
      />
      <ViewDBCTableModal
        open={viewDBCTableModal}
        onOpen={() => setViewDBCTableModal(true)}
        onClose={() => setViewDBCTableModal(false)}
        parsedDBC={parsedDBCData}
      />
      <UpdateDBCParsingModal
        isOpen={isUpdateDBCParsingModalOpen}
        onClose={() => setIsUpdateDBCParsingModalOpen(false)}
        selectedDBC={selectedDBC}
      />
      <Grid.Row>
        <Grid.Column>
          <Button
            id="addDBCButton"
            primary
            floated="right"
            icon
            labelPosition="left"
            onClick={() => {
              setOperationType(DBCOperationType.Create);
              setOpen(true);
            }}
          >
            <Icon name="plus" />
            Add DBC Parser
          </Button>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column>
          <Table id="DBCTable" celled fixed>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell width={1}>ID</Table.HeaderCell>
                <Table.HeaderCell width={2}>Name</Table.HeaderCell>
                <Table.HeaderCell width={2}>Version</Table.HeaderCell>
                <Table.HeaderCell width={2}>Input Table</Table.HeaderCell>
                <Table.HeaderCell width={2}>Output Table</Table.HeaderCell>
                <Table.HeaderCell width={1}>Cycle Time</Table.HeaderCell>
                <Table.HeaderCell width={1}>Status</Table.HeaderCell>
                <Table.HeaderCell width={3}>
                  Last Processed Timestamp
                </Table.HeaderCell>
                <Table.HeaderCell width={3}>Actions</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {DBCData.length !== 0 ? (
                DBCData.map((dbc) => {
                  const sortedOffsets = sortShards(dbc.offsets);
                  return (
                    <Table.Row key={dbc.id}>
                      <Table.Cell>
                        <TextWithToolTip text={dbc.id} />
                      </Table.Cell>
                      <Table.Cell>
                        <TextWithToolTip text={dbc.name} />
                      </Table.Cell>
                      <Table.Cell>
                        <TextWithToolTip text={dbc.version} />
                      </Table.Cell>
                      <Table.Cell>
                        <TextWithToolTip text={dbc.input_table} />
                      </Table.Cell>
                      <Table.Cell>
                        <TextWithToolTip text={dbc.output_table} />
                      </Table.Cell>
                      <Table.Cell>{dbc.period} ms</Table.Cell>
                      <Table.Cell>
                        {capitalizeFirstLetter(dbc.status || "--")}
                      </Table.Cell>
                      {Object.keys(sortedOffsets).length !== 0 ? (
                        <Table.Cell>
                          {Object.keys(sortedOffsets).map((key) => {
                            return (
                              <>
                                {sortedOffsets[key] ? (
                                  <Popup
                                    content={moment(sortedOffsets[key]).format(
                                      "MMMM Do YYYY, h:mm:ss a"
                                    )}
                                    position="top center"
                                    inverted
                                    trigger={
                                      <OffsetDiv key={key}>
                                        {key}:
                                        <span style={{ paddingRight: "5px" }} />
                                        {moment
                                          .duration(
                                            sortedOffsets[key] -
                                              new Date().valueOf()
                                          )
                                          .humanize()}{" "}
                                        ago
                                      </OffsetDiv>
                                    }
                                  />
                                ) : (
                                  "--"
                                )}
                              </>
                            );
                          })}
                        </Table.Cell>
                      ) : (
                        <Table.Cell textAlign="center">--</Table.Cell>
                      )}
                      <Table.Cell>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "flex-start",
                            gap: "12px",
                            flexWrap: "nowrap",
                          }}
                        >
                          <Popup
                            trigger={
                              <ButtonGroupedIcon
                                icon={{ name: "file alternate outline" }}
                                cornerIcon={{
                                  name: "pencil",
                                  corner: "bottom right",
                                }}
                                onClick={() => {
                                  setIsUpdateDBCParsingModalOpen(true);
                                  setSelectedDBC(dbc);
                                }}
                                style={{
                                  marginTop: "4px",
                                }}
                              />
                            }
                            content="Update DBC Parsing"
                            position="top center"
                            inverted
                          />
                          <Popup
                            trigger={
                              <ButtonIcon
                                name="edit"
                                onClick={() => {
                                  editDBCParser(dbc.id);
                                }}
                              />
                            }
                            content={"View/Edit DBC Parser"}
                            position="top center"
                            inverted
                          />
                          <Popup
                            trigger={
                              <ButtonIcon
                                name="eye"
                                onClick={() => {
                                  fetchDBCJSON(dbc.id);
                                }}
                              />
                            }
                            content="View Parsed DBC"
                            position="top center"
                            inverted
                          />
                          <Popup
                            trigger={
                              <BrowserUpdatedIcon
                                height="14px"
                                id={`downloadDBCIcon${dbc.id}`}
                                style={{
                                  cursor: "pointer",
                                  marginTop: "8px",
                                  opacity: "0.7",
                                }}
                                onClick={() => {
                                  downloadDBCFile(dbc.id, dbc.name);
                                }}
                              />
                            }
                            content="Download DBC file"
                            position="top center"
                            inverted
                          />
                          {renderToggleButton(dbc, dbc.status ?? "stopped")}
                          {renderDeleteButton(dbc)}
                        </div>
                      </Table.Cell>
                    </Table.Row>
                  );
                })
              ) : (
                <Table.Row>
                  <Table.Cell colSpan={11}>
                    <ErrorMessage message={"No DBCs found!"} />
                  </Table.Cell>
                </Table.Row>
              )}
            </Table.Body>
          </Table>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
}

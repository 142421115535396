import React, { createRef, useEffect, useState } from "react";
import {
  Button,
  Checkbox,
  Form,
  Grid,
  Icon,
  Modal,
  Popup,
  Radio,
  Table,
} from "semantic-ui-react";
import { DBCResponse } from "../../../../util";
import {
  StyledFileInput,
  StyledFileUploadButton,
} from "../../DeviceManagement/Devices/ActionModals/SendFileModal";
import AnimatedEllipsis from "../../common/AnimatedEllipsis";
import { beamtoast } from "../../../common/CustomToast";
import {
  DBCDiffResponse,
  DBCSignalWithCAN,
  updateDBCParsing,
  uploadNewDBCForDiff,
} from "../../../../BytebeamClient";
import styled from "styled-components";
import * as uuid from "uuid";
import { AxiosResponse } from "axios";

type DBCSignalType = DBCSignalWithCAN & { id: string };

type DBCDiffResType = {
  deleted_signals: DBCSignalType[];
  added_signals: DBCSignalType[];
  updated_signals: {
    from: DBCSignalType;
    to: DBCSignalType;
  }[];
};

enum UpdateDBCParsingSteps {
  UploadNewDBCFile,
  CompareAndUpdateDBCParsing,
}

type UploadNewDBCContentProps = {
  readonly showDBCUploadProgress: boolean;
  readonly dbcFileLoaded: number;
  readonly dbcFileTotal: number;
  readonly dbcFileName: string;
  readonly uploadedDBCFileResponse: AxiosResponse<DBCDiffResponse>;
  readonly onSelect: (e: any) => void;
};

function UploadNewDBCContent(props: UploadNewDBCContentProps) {
  const {
    showDBCUploadProgress,
    dbcFileLoaded,
    dbcFileTotal,
    dbcFileName,
    uploadedDBCFileResponse,
    onSelect,
  } = props;
  const DBCFileInput = createRef<any>();

  return (
    <div>
      <p>
        Upload a new DBC file to compare the DBC parsing with the existing DBC
        file.
      </p>
      <Form>
        <Form.Field>
          <label htmlFor="upload_file">
            <p
              style={{
                fontSize: "1.1rem",
              }}
            >
              Upload file from the system
            </p>
          </label>
          <div style={{ position: "relative" }}>
            <StyledFileUploadButton
              fluid
              content="Select File"
              labelPosition="left"
              icon="file"
            />
            <StyledFileInput
              type="file"
              id="upload_file"
              ref={DBCFileInput}
              onChange={onSelect}
            />
          </div>
          <label htmlFor="file_name" style={{ marginTop: "12px" }}>
            File Chosen:{" "}
          </label>
          <span id="file_name">
            {dbcFileName !== "" ? dbcFileName : "No File Chosen"}
          </span>
        </Form.Field>
        {showDBCUploadProgress && (
          <Form.Field>
            <label htmlFor="file-progress">
              {uploadedDBCFileResponse.status === 0 ? (
                <span>
                  File Uploading
                  <AnimatedEllipsis spacing={3} dotSize={"8px"} />
                </span>
              ) : (
                <span>File Uploaded</span>
              )}
            </label>
            <progress
              id="file-progress"
              max={dbcFileTotal}
              value={dbcFileLoaded}
            />
          </Form.Field>
        )}
      </Form>
    </div>
  );
}

const Paragraph = styled.p`
  margin: 0;
`;

const BoldSpan = styled.span`
  margin-right: 12px;
  font-weight: bold;
`;

type SignalPopupContentProps = {
  readonly signal: DBCSignalWithCAN;
};

function SignalPopupContent({ signal }: SignalPopupContentProps) {
  return (
    <Popup
      inverted
      trigger={
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "fit-content",
            cursor: "pointer",
          }}
        >
          <Paragraph>
            <BoldSpan>CAN ID:</BoldSpan> {signal.can_id}
          </Paragraph>
          <Paragraph>
            <BoldSpan>Start:</BoldSpan> {signal.start}
          </Paragraph>
          <Paragraph>
            <BoldSpan>Name:</BoldSpan> {signal.name ?? "--"}
          </Paragraph>
        </div>
      }
      content={
        <div
          style={{ display: "flex", flexDirection: "column", padding: "12px" }}
        >
          <Paragraph>
            <BoldSpan>CAN ID:</BoldSpan> {signal.can_id ?? "--"}
          </Paragraph>
          <Paragraph>
            <BoldSpan>Start:</BoldSpan> {signal.start ?? "--"}
          </Paragraph>
          <Paragraph>
            <BoldSpan>Choices:</BoldSpan> {signal.choices ?? "--"}
          </Paragraph>
          <Paragraph>
            <BoldSpan>Name:</BoldSpan> {signal.name ?? "--"}
          </Paragraph>
          <Paragraph>
            <BoldSpan>Database Column:</BoldSpan>{" "}
            {signal.database_column ?? "--"}
          </Paragraph>
          <Paragraph>
            <BoldSpan>Byte Order:</BoldSpan> {signal.byte_order ?? "--"}
          </Paragraph>
          <Paragraph>
            <BoldSpan>Scale:</BoldSpan> {signal.scale ?? "--"}
          </Paragraph>
          <Paragraph>
            <BoldSpan>Is Signed:</BoldSpan> {`${signal.is_signed ?? "--"}`}
          </Paragraph>
          <Paragraph>
            <BoldSpan>Is Float:</BoldSpan> {`${signal.is_float ?? "--"}`}
          </Paragraph>
          <Paragraph>
            <BoldSpan>Length:</BoldSpan> {signal.length ?? "--"}
          </Paragraph>
          <Paragraph>
            <BoldSpan>Offset:</BoldSpan> {signal.offset ?? "--"}
          </Paragraph>
        </div>
      }
      position="top center"
    />
  );
}

const StyledRadio = styled(Radio)`
  &&& {
    .dark .ui.checkbox input:checked ~ label:after {
      background-color: green !important; /* Make the checkmark green */
    }
    margin-right: 16px;
  }
`;

type DBCDiffContentProps = {
  readonly dbcDiff: DBCDiffResType;
  readonly updatedSignals: { from: DBCSignalType; to: DBCSignalType }[];
  readonly setUpdatedSignals: (
    updatedSignals: { from: DBCSignalType; to: DBCSignalType }[]
  ) => void;
};

function DBCDiffContent(props: DBCDiffContentProps) {
  const { dbcDiff, updatedSignals, setUpdatedSignals } = props;

  const [deletedSignals, setDeletedSignals] = useState<DBCSignalType[]>(
    dbcDiff.deleted_signals
  );
  const [addedSignals, setAddedSignals] = useState<DBCSignalType[]>(
    dbcDiff.added_signals
  );

  const [selectedDeletedSignal, setSelectedDeletedSignal] =
    useState<DBCSignalType>(dbcDiff.deleted_signals?.[0] ?? {});
  const [selectedAddedSignal, setSelectedAddedSignal] = useState<DBCSignalType>(
    dbcDiff.added_signals?.[0] ?? {}
  );
  const [selectedUpdatedSignals, setSelectedUpdatedSignals] = useState<
    { from: DBCSignalType; to: DBCSignalType }[]
  >([]);

  function addToUpdatedSignals() {
    const updatedSignal = {
      from: selectedDeletedSignal,
      to: selectedAddedSignal,
    };
    setUpdatedSignals([...updatedSignals, updatedSignal]);
    setDeletedSignals(
      deletedSignals.filter(
        (signal) => signal?.id !== selectedDeletedSignal?.id
      )
    );
    setAddedSignals(
      addedSignals.filter((signal) => signal?.id !== selectedAddedSignal?.id)
    );

    setSelectedDeletedSignal({} as DBCSignalType);
    setSelectedAddedSignal({} as DBCSignalType);
  }

  function removeFromUpdatedSignals() {
    const updatedSignalsAfterRemoval = updatedSignals.filter(
      (diff) =>
        !selectedUpdatedSignals.some(
          (selectedUpdatedSignal) =>
            diff?.from?.id === selectedUpdatedSignal?.from?.id &&
            diff?.to?.id === selectedUpdatedSignal?.to?.id
        )
    );

    const deletedSignalsToAdd = selectedUpdatedSignals.map(
      (selectedUpdatedSignal) => selectedUpdatedSignal?.from
    );
    const addedSignalsToAdd = selectedUpdatedSignals.map(
      (selectedUpdatedSignal) => selectedUpdatedSignal?.to
    );

    setUpdatedSignals(updatedSignalsAfterRemoval);
    setDeletedSignals([...deletedSignals, ...deletedSignalsToAdd]);
    setAddedSignals([...addedSignals, ...addedSignalsToAdd]);

    setSelectedUpdatedSignals([]);
  }

  useEffect(() => {
    setDeletedSignals(dbcDiff.deleted_signals);
    setAddedSignals(dbcDiff.added_signals);
    setUpdatedSignals(dbcDiff.updated_signals);
  }, [dbcDiff.deleted_signals, dbcDiff.added_signals, dbcDiff.updated_signals]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Grid>
      <Grid.Row style={{ width: "100%" }}>
        <Grid.Column style={{ width: "23%" }}>
          <Table fixed>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>
                  Deleted Signals{" "}
                  <Popup
                    inverted
                    trigger={
                      <Icon
                        name="question circle"
                        style={{ marginBottom: "2px", marginLeft: "12px" }}
                      />
                    }
                    content={
                      "These signals will be removed from the DBC but the corresponding columns will not be deleted from the database."
                    }
                    position="top center"
                  />
                </Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {deletedSignals?.length === 0 && (
                <Table.Row>
                  <Table.Cell textAlign="center">No Deleted Signals</Table.Cell>
                </Table.Row>
              )}
              {deletedSignals?.map((signal) => {
                return (
                  <Table.Row
                    key={signal?.id}
                    onClick={(e) => {
                      e.stopPropagation();
                      setSelectedDeletedSignal(signal);
                    }}
                    style={{ cursor: "pointer" }}
                  >
                    <Table.Cell>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                        }}
                      >
                        <StyledRadio
                          name={"deleted_signal"}
                          value={signal?.id}
                          checked={selectedDeletedSignal?.id === signal?.id}
                          onChange={(e) => {
                            e.stopPropagation();
                            setSelectedDeletedSignal(signal);
                          }}
                        />
                        <SignalPopupContent signal={signal} />
                      </div>
                    </Table.Cell>
                  </Table.Row>
                );
              })}
            </Table.Body>
          </Table>
        </Grid.Column>
        <Grid.Column style={{ width: "23%" }}>
          <Table fixed>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>
                  Added Signals{" "}
                  <Popup
                    inverted
                    trigger={
                      <Icon
                        name="question circle"
                        style={{ marginBottom: "2px", marginLeft: "12px" }}
                      />
                    }
                    content={
                      "These signals will be added to the DBC and the corresponding new columns will be created in the database."
                    }
                    position="top center"
                  />
                </Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {addedSignals?.length === 0 && (
                <Table.Row>
                  <Table.Cell textAlign="center">No Added Signals</Table.Cell>
                </Table.Row>
              )}
              {addedSignals?.map((signal) => {
                return (
                  <Table.Row
                    key={signal?.id}
                    onClick={(e) => {
                      e.stopPropagation();
                      setSelectedAddedSignal(signal);
                    }}
                    style={{ cursor: "pointer" }}
                  >
                    <Table.Cell>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                        }}
                      >
                        <StyledRadio
                          name={"added_signal"}
                          value={signal?.id}
                          checked={selectedAddedSignal?.id === signal?.id}
                          onChange={(e) => {
                            e.stopPropagation();
                            setSelectedAddedSignal(signal);
                          }}
                        />
                        <SignalPopupContent signal={signal} />
                      </div>
                    </Table.Cell>
                  </Table.Row>
                );
              })}
            </Table.Body>
          </Table>
        </Grid.Column>
        <Grid.Column
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            gap: "20px",
            width: "14%",
          }}
        >
          <Button
            onClick={addToUpdatedSignals}
            disabled={
              selectedDeletedSignal?.can_id === undefined ||
              selectedAddedSignal?.can_id === undefined
            }
          >
            Add to Updated Signals
            <Icon name="angle double right" />
          </Button>
          <Button
            onClick={removeFromUpdatedSignals}
            disabled={selectedUpdatedSignals?.length === 0}
          >
            <Icon name="angle double left" />
            Remove from Updated Signals
          </Button>
        </Grid.Column>
        <Grid.Column style={{ width: "40%" }}>
          <Table fixed>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell colSpan="3">
                  Updated Signals{" "}
                  <Popup
                    inverted
                    trigger={
                      <Icon
                        name="question circle"
                        style={{ marginBottom: "2px", marginLeft: "12px" }}
                      />
                    }
                    content={
                      "These signals will be updated in the DBC and the columns in the database will be updated as required."
                    }
                    position="top center"
                  />
                </Table.HeaderCell>
              </Table.Row>
              <Table.Row>
                <Table.HeaderCell>
                  <Checkbox
                    value={updatedSignals?.length}
                    onChange={(e, data) => {
                      e.stopPropagation();
                      if (data.checked) {
                        setSelectedUpdatedSignals(updatedSignals);
                      } else {
                        setSelectedUpdatedSignals([]);
                      }
                    }}
                    checked={
                      selectedUpdatedSignals?.length ===
                        updatedSignals?.length && updatedSignals?.length !== 0
                    }
                  />
                </Table.HeaderCell>
                <Table.HeaderCell>From</Table.HeaderCell>
                <Table.HeaderCell>To</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {updatedSignals?.length === 0 && (
                <Table.Row>
                  <Table.Cell textAlign="center" colSpan="3">
                    No Updated Signals
                  </Table.Cell>
                </Table.Row>
              )}
              {updatedSignals.map((updatedSignal, index) => {
                return (
                  <Table.Row
                    key={`${updatedSignal?.from?.id}-${updatedSignal?.to?.id}`}
                  >
                    <Table.Cell>
                      <Checkbox
                        value={index}
                        onChange={(e, data) => {
                          e.stopPropagation();
                          if (data.checked) {
                            setSelectedUpdatedSignals([
                              ...selectedUpdatedSignals,
                              updatedSignal,
                            ]);
                          } else {
                            setSelectedUpdatedSignals(
                              selectedUpdatedSignals.filter(
                                (selectedUpdatedSignal) =>
                                  selectedUpdatedSignal?.from?.id !==
                                    updatedSignal?.from?.id &&
                                  selectedUpdatedSignal?.to?.id !==
                                    updatedSignal?.to?.id
                              )
                            );
                          }
                        }}
                        checked={selectedUpdatedSignals.some(
                          (selectedUpdatedSignal) =>
                            selectedUpdatedSignal?.from?.id ===
                              updatedSignal?.from?.id &&
                            selectedUpdatedSignal?.to?.id ===
                              updatedSignal?.to?.id
                        )}
                      />
                    </Table.Cell>
                    <Table.Cell>
                      <SignalPopupContent signal={updatedSignal?.from} />
                    </Table.Cell>
                    <Table.Cell>
                      <SignalPopupContent signal={updatedSignal?.to} />
                    </Table.Cell>
                  </Table.Row>
                );
              })}
            </Table.Body>
          </Table>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
}

type UpdateDBCParsingModalProps = {
  readonly isOpen: boolean;
  readonly onClose: () => void;
  readonly selectedDBC?: DBCResponse;
};

function UpdateDBCParsingModal(props: UpdateDBCParsingModalProps) {
  const { isOpen, onClose, selectedDBC } = props;

  const [currentStep, setCurrentStep] = useState<UpdateDBCParsingSteps>(
    UpdateDBCParsingSteps.UploadNewDBCFile
  );

  const [dbcDiff, setDBCDiff] = useState<DBCDiffResType>({
    deleted_signals: [],
    added_signals: [],
    updated_signals: [],
  });
  const [dbcFileName, setDbcFileName] = useState<string>("");
  const [dbcFile, setDbcFile] = useState<File>(new File([""], "dbc-file"));
  const [showDBCUploadProgress, setShowDBCUploadProgress] =
    useState<boolean>(false);
  const [dbcFileLoaded, setDbcFileLoaded] = useState<number>(0);
  const [dbcFileTotal, setDbcFileTotal] = useState<number>(0);
  const [uploadedDBCFileResponse, setUploadedDBCFileResponse] = useState<
    AxiosResponse<DBCDiffResponse>
  >({ status: 0 } as AxiosResponse<DBCDiffResponse>);

  const [updatedSignals, setUpdatedSignals] = useState<
    { from: DBCSignalType; to: DBCSignalType }[]
  >(dbcDiff.updated_signals);

  const onSelect = (e) => {
    setShowDBCUploadProgress(false);
    setDbcFileLoaded(0);
    setDbcFileTotal(0);

    if (e.target.files.length !== 0) {
      const selectedFile = e.target.files[0];
      if (selectedFile) {
        const fileName = selectedFile.name;
        const fileExtension = fileName.split(".").pop();
        if (fileExtension === "dbc") {
          setDbcFile(selectedFile);
          setDbcFileName(fileName);
        } else {
          beamtoast.error(`Only ".dbc" file types are allowed!`);
          setShowDBCUploadProgress(false);
          setDbcFileLoaded(0);
          setDbcFileTotal(0);
          setDbcFile(new File([""], "filename"));
          setDbcFileName("");
        }
      }
    }
  };

  const uploadDBC = async () => {
    const formData = new FormData();

    if (dbcFileName) {
      formData.append("file", dbcFile);
      formData.append("name", dbcFileName);
    } else {
      beamtoast.error("Please upload new dbc file to proceed further.");
      return;
    }

    try {
      const res = await uploadNewDBCForDiff(
        selectedDBC?.id as string,
        formData,
        (p) => {
          setDbcFileLoaded(p.loaded);
          setDbcFileTotal(p.total);
        }
      );

      setUploadedDBCFileResponse(res);

      if (res.status === 200) {
        let resData: DBCDiffResponse = await res.data;
        let dbcRes: DBCDiffResType = {
          deleted_signals: resData.deleted_signals.map((signal) => ({
            id: uuid.v4(),
            ...signal,
          })),
          added_signals: resData.added_signals.map((signal) => ({
            id: uuid.v4(),
            ...signal,
          })),
          updated_signals: resData.updated_signals.map((signal) => ({
            from: {
              id: uuid.v4(),
              ...signal.from,
            },
            to: {
              id: uuid.v4(),
              ...signal.to,
            },
          })),
        };

        setDBCDiff(dbcRes);
        setCurrentStep(UpdateDBCParsingSteps.CompareAndUpdateDBCParsing);
      }
    } catch (error: any) {
      console.log(error);

      // Handling Error for File Size exceeding limit from NGINX
      if (String(error)?.includes("413")) {
        beamtoast.error("Upload failed due to size limit!");
      }

      // Handling other error types
      const errorMessage = error.response?.data?.error;

      if (errorMessage?.includes("invalid continuation byte")) {
        beamtoast.error("Uploaded file is not supported!");
      } else {
        beamtoast.error("Failed to upload DBC: ", errorMessage);
      }

      console.log(errorMessage);
    }
  };

  const handleUpdateDBCParsing = async () => {
    try {
      const updated_signals = updatedSignals.map((signal) => {
        return {
          from: {
            can_id: signal.from.can_id,
            start: signal.from.start,
            name: signal.from.name,
            choices: signal.from.choices,
            database_column: signal.from.database_column,
            byte_order: signal.from.byte_order,
            scale: signal.from.scale,
            is_signed: signal.from.is_signed,
            is_float: signal.from.is_float,
            length: signal.from.length,
            offset: signal.from.offset,
          },
          to: {
            can_id: signal.to.can_id,
            start: signal.to.start,
            name: signal.to.name,
            choices: signal.to.choices,
            database_column: signal.to.database_column,
            byte_order: signal.to.byte_order,
            scale: signal.to.scale,
            is_signed: signal.to.is_signed,
            is_float: signal.to.is_float,
            length: signal.to.length,
            offset: signal.to.offset,
          },
        };
      });

      const formData = new FormData();
      formData.append("file", dbcFile);
      formData.append("updated_signals", JSON.stringify(updated_signals));

      const res = await updateDBCParsing(selectedDBC?.id as string, formData);

      if (res) {
        beamtoast.success("DBC Parsing updated successfully");
        onModalClose();
      }
    } catch (error: any) {
      console.log(error);
      beamtoast.error("Failed to update DBC Parsing");
    }
  };

  function onModalClose() {
    // Resetting the state
    setCurrentStep(UpdateDBCParsingSteps.UploadNewDBCFile);
    setDbcFileName("");
    setDbcFile(new File([""], "filename"));
    setShowDBCUploadProgress(false);
    setDbcFileLoaded(0);
    setDbcFileTotal(0);
    setUploadedDBCFileResponse({ status: 0 } as AxiosResponse<DBCDiffResponse>);
    setDBCDiff({
      deleted_signals: [],
      added_signals: [],
      updated_signals: [],
    });

    // Closing the modal
    onClose();
  }

  return (
    <Modal
      open={isOpen}
      onClose={onModalClose}
      size={
        currentStep === UpdateDBCParsingSteps.UploadNewDBCFile
          ? "tiny"
          : "fullscreen"
      }
      className="dark"
      closeOnDimmerClick={false}
      closeOnEscape={false}
    >
      <Modal.Header>
        {currentStep === UpdateDBCParsingSteps.UploadNewDBCFile
          ? "Upload New DBC File"
          : "Compare and Update DBC Parsing"}
      </Modal.Header>

      <Modal.Content>
        {currentStep === UpdateDBCParsingSteps.UploadNewDBCFile ? (
          <UploadNewDBCContent
            showDBCUploadProgress={showDBCUploadProgress}
            dbcFileLoaded={dbcFileLoaded}
            dbcFileTotal={dbcFileTotal}
            dbcFileName={dbcFileName}
            uploadedDBCFileResponse={uploadedDBCFileResponse}
            onSelect={onSelect}
          />
        ) : (
          <DBCDiffContent
            dbcDiff={dbcDiff}
            updatedSignals={updatedSignals}
            setUpdatedSignals={setUpdatedSignals}
          />
        )}
      </Modal.Content>
      <Modal.Actions>
        <Button
          id="cancel_button"
          secondary
          onClick={() => {
            onModalClose();
          }}
        >
          <Icon name="remove" /> Cancel
        </Button>
        <Button
          id="submit_button"
          primary
          onClick={async (e) => {
            if (currentStep === UpdateDBCParsingSteps.UploadNewDBCFile) {
              uploadDBC();
            } else if (
              currentStep === UpdateDBCParsingSteps.CompareAndUpdateDBCParsing
            ) {
              handleUpdateDBCParsing();
            }
          }}
        >
          <Icon
            name={
              currentStep === UpdateDBCParsingSteps.UploadNewDBCFile
                ? "angle right"
                : "checkmark"
            }
            style={{ marginLeft: "0px", marginRight: "6px" }}
          />{" "}
          {currentStep === UpdateDBCParsingSteps.UploadNewDBCFile
            ? "Proceed"
            : "Update DBC Parsing"}
        </Button>
      </Modal.Actions>
    </Modal>
  );
}

export default UpdateDBCParsingModal;

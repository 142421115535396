import React from "react";
import { Icon, IconGroup, IconProps, SemanticICONS } from "semantic-ui-react";
import styled from "styled-components";
import {
  FetchStreamsAPIResponse,
  getTenantFromURL,
} from "../../BytebeamClient";
import moment from "moment";
import { beamtoast } from "../common/CustomToast";
import { PhaseData, TimestampTriggerType } from "./Actions/NewAction/NewAction";
import { getSelectedPhaseIndex } from "./Actions/util";

export const Row = styled.div`
  display: flex;
  flex-direction: row;
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ButtonIcon = (props: IconProps) => {
  return (
    <Icon style={{ cursor: "pointer", ...(props.style || {}) }} {...props} />
  );
};

interface ButtonGroupedIconProps extends IconProps {
  icon: IconProps;
  cornerIcon: IconProps;
}

export const ButtonGroupedIcon = (props: ButtonGroupedIconProps) => {
  const { icon, cornerIcon, style, ...restProps } = props;
  return (
    <IconGroup
      style={{ cursor: "pointer", ...(props.style || {}) }}
      {...restProps}
    >
      <Icon {...props.icon} />
      <Icon {...props.cornerIcon} style={{ textShadow: "none" }} />
    </IconGroup>
  );
};

interface IconGroupProps extends IconProps {
  icon1: SemanticICONS | undefined;
  icon2: SemanticICONS | undefined;
}

export const ButtonIconGroup = (props: IconGroupProps) => {
  return (
    <IconGroup>
      <Icon
        style={{
          cursor: `${props.disabled ? "not-allowed" : "pointer"}`,
          opacity: `${props.disabled ? 0.5 : 1}`,
          ...(props.style || {}),
        }}
        {...props}
        name={props.icon1}
      />
      <Icon
        corner
        style={{
          cursor: `${props.disabled ? "not-allowed" : "pointer"}`,
          opacity: `${props.disabled ? 0.5 : 1}`,
          ...(props.style || {}),
        }}
        {...props}
        name={props.icon2}
      />
    </IconGroup>
  );
};

interface DisplayIfProps {
  cond: boolean;
  children: React.ReactNode;
}

export const DisplayIf: React.FC<DisplayIfProps> = ({ cond, children }) => {
  const tenant = getTenantFromURL();

  if ((cond || tenant === "demo") && children) {
    return <>{children}</>;
  }

  return null;
};

export function capitalizeFirstLetter(string: string) {
  if (string) {
    return string
      ?.split("_")
      ?.map((word) => word?.charAt(0)?.toUpperCase() + word?.slice(1))
      ?.join(" ");
  } else return "";
}

export function getFileExtension(filename) {
  return filename.split(".").pop();
}

export function getHumanReadableFileSizeString(
  bytes: number,
  standard: string = "iec", // si = 1000, iec = 1024 threshold
  decimalPlaces: number = 1
) {
  // SI units are power-of-ten based, IEC units are power-of-two based
  const threshold = standard === "si" ? 1000 : 1024;

  let unitIndex = -1;
  const rounded = 10 ** decimalPlaces;

  const units = standard === "si" ? ["kB", "MB", "GB"] : ["KiB", "MiB", "GiB"];

  if (Math.abs(bytes) < threshold) {
    return bytes + " B";
  }

  do {
    bytes /= threshold;
    ++unitIndex;
  } while (
    Math.round(Math.abs(bytes) * rounded) / rounded >= threshold &&
    unitIndex < units.length - 1
  );

  return bytes.toFixed(decimalPlaces) + " " + units[unitIndex];
}

// Stream Info API
export const filterTableInfo = (tablesObj) => {
  const filteredTablesInfo = Object.keys(tablesObj)
    .filter((tableName) => !tableName.endsWith("_local"))
    .reduce((filteredTableObj, tableName) => {
      return Object.assign(filteredTableObj, {
        [tableName]: tablesObj[tableName],
      });
    }, {});
  return filteredTablesInfo;
};

// Filtering Streams Table Data, detailed with types
export function filterStreamTableInfo(
  tablesObj: FetchStreamsAPIResponse,
  filterUplinkTables = false
) {
  const filteredTables = {};
  Object.keys(tablesObj).forEach((stream) => {
    let streamName = stream;
    if (filterUplinkTables) {
      if (!streamName.endsWith("_local") && !streamName.startsWith("uplink_")) {
        filteredTables[streamName] = Object.keys(tablesObj[stream].fields)
          .filter(
            (field) => !(field.startsWith(".") && field.endsWith("_timestamp"))
          )
          .map((item) => {
            return {
              name: item,
              type: tablesObj[stream]?.fields[item]?.type,
              seq_id: tablesObj[stream]?.fields[item]?.seq_id,
              required: tablesObj[stream]?.fields[item]?.required,
              unit: tablesObj[stream]?.fields[item]?.unit,
            };
          });
      }
    } else {
      if (!streamName.endsWith("_local")) {
        filteredTables[streamName] = Object.keys(tablesObj[stream].fields)
          .filter(
            (field) => !(field.startsWith(".") && field.endsWith("_timestamp"))
          )
          .map((item) => {
            return {
              name: item,
              type: tablesObj[stream]?.fields[item]?.type,
              seq_id: tablesObj[stream]?.fields[item]?.seq_id,
              required: tablesObj[stream]?.fields[item]?.required,
              unit: tablesObj[stream]?.fields[item]?.unit,
            };
          });
      }
    }
  });
  return filteredTables;
}

// Function to check if string has special characters except underscore and space.
export function hasSpecialCharacters(str: string) {
  const regex = /[^a-zA-Z0-9\s_]/;
  return regex.test(str);
}

export async function copyContentToClipboard(
  text: string | number,
  showTextWhenCopied: boolean = true
) {
  try {
    await navigator.clipboard.writeText(`${text}`);
    if (text) {
      beamtoast.success(
        showTextWhenCopied
          ? `"${text}" copied to clipboard`
          : "Content Copied to clipboard"
      );
    }
  } catch (err) {
    console.error("Failed to copy: ", err);
  }
}

export const validateTimestampInterval = (
  timestamp: Date | null | number,
  activePhase: string,
  phasesData: PhaseData[],
  shouldTriggerImmediately: boolean
) => {
  if (!timestamp) return true;

  // Filter phases to include only those with a TimestampTriggerType
  const phasesWithTimestamp = phasesData.filter(
    (phase) => "timestamp" in phase.trigger_on
  );

  const activePhaseIndex = getSelectedPhaseIndex(activePhase);

  // Check if the difference between the selected timestamp and the timestamp of the previous phase is at least 5 minutes
  if (activePhaseIndex > 0 && phasesWithTimestamp.length > 1) {
    const prevPhaseWithTimestamp = phasesWithTimestamp.findLast(
      (phase) => phase.id <= activePhaseIndex
    )?.trigger_on as TimestampTriggerType;
    const prevTimestamp = prevPhaseWithTimestamp?.timestamp;

    const diffInMinutes = moment(timestamp).diff(
      moment(prevTimestamp),
      "minutes"
    );
    return diffInMinutes >= 5;
  } else if (activePhaseIndex === 0 && !shouldTriggerImmediately) {
    const diffInMinutes = moment(timestamp).diff(moment(new Date()), "minutes");
    return diffInMinutes >= 5;
  }

  return true;
};

export const HARDWARE_TYPES = [
  "ESP32",
  "Arduino",
  "Bytebeam",
  "Raspberry Pi",
  "BeagleBone Black",
  "Linux",
  "Android",
  "Other",
];

// Mapping device type to device options in getting started page
export const mappingHardwareToDeviceOptions = {
  ESP32: "ESP32 (ESP-IDF)",
  Arduino: "Arduino",
  "Raspberry Pi": "Linux",
  "BeagleBone Black": "Linux",
  Linux: "Linux",
  Android: "ESP32 (ESP-IDF)",
  Other: "ESP32 (ESP-IDF)",
};

// Mapping device type to device options in getting started page
export const mappingDeviceTypeToDeviceOptions = {
  esp32: "ESP32 (ESP-IDF)",
  arduino: "Arduino",
  "raspberry pi": "Linux",
  "beaglebone black": "Linux",
  linux: "Linux",
  bytebeam: "ESP32 (ESP-IDF)",
  android: "ESP32 (ESP-IDF)",
  other: "ESP32 (ESP-IDF)",
};

export function formatDuration(seconds) {
  const duration = moment.duration(seconds, "seconds");
  const yrs = duration.years();
  const d = duration.days();
  const hrs = duration.hours();
  const mins = duration.minutes();
  const secs = duration.seconds();
  const millisecs = duration.milliseconds();
  const decimalSecs = secs + millisecs / 1000;

  let result = "";
  if (yrs > 0) result += `${yrs} yr${yrs > 1 ? "s" : ""} `;
  if (d > 0) result += `${d} d `;
  if (hrs > 0) result += `${hrs} hr${hrs > 1 ? "s" : ""} `;
  if (mins > 0) result += `${mins} min `;
  if (decimalSecs > 0) result += `${Number(decimalSecs.toFixed(3))} sec`;
  if (yrs === 0 && d === 0 && hrs === 0 && mins === 0 && decimalSecs === 0)
    result = "0 sec";

  return result.trim();
}

export const debounce = (func: Function, delay: number) => {
  let timeoutId: NodeJS.Timeout;
  return (...args: any[]) => {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => func(...args), delay);
  };
};

export const isNumericType = (input: string): boolean => {
  return /^(Nullable\()?(Int|UInt|Float)\d*(?:\(\d+\))?/.test(input);
};

export function isJSONValid(str: string): boolean {
  try {
    let parsedJSON = JSON.parse(str);

    if (
      typeof parsedJSON === "object" &&
      parsedJSON !== null &&
      !Array.isArray(parsedJSON)
    ) {
      return true;
    } else {
      return false;
    }
  } catch (e) {
    return false;
  }
}
